import { combineReducers } from "redux";

import authReducer from "./auth";
import menuReducer from "./nav";

const allReducers = combineReducers({
  authreducer: authReducer,
  menu: menuReducer,
});

export default allReducers;